<template>
  <div style="padding-bottom: 200px">
    <form @submit.prevent="submitHandler" @keyup.enter="handleKeyPress">
      <div
        class="formgrid p-fluid grid justify-content-around align-items-center"
      >
        <Divider class="m-2 mb-5"></Divider>
        <div class="field col-12 sm:col-6">
          <label for="name" style="color: #687a8d !important">Cost Type</label>
          <div v-if="!costObj" class="mr-3">
            <Dropdown
              :options="cost_types"
              class="filterDD"
              dataKey="uid"
              :filter="true"
              optionLabel="type_name"
              placeholder="Cost Type"
              v-model="cost.item"
              @change="handleCostSelect(cost.item)"
            >
              <template #indicator> <i class="la la-filter" /> </template
            ></Dropdown>
          </div>
          <div v-else>
            {{ cost?.type_name }}
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.cost.item.required"
          >
            Cost type is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Supplier</label>
          <div class="p-inputgroup">
            <Dropdown
              :options="accountSuppliers"
              dataKey="uid"
              optionLabel="company_name"
              optionValue="uid"
              :disabled="!cost.item"
              placeholder="Select Supplier"
              class="input"
              v-model="cost.supplier"
            />
          </div>

          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.cost.supplier.required"
          >
            Supplier is required
          </div>
        </div>

        <div class="field col-12 sm:col-6">
          <label>Cost Name</label>
          <div class="p-inputgroup">
            <InputText
              placeHolder="Cost Name (optional)"
              v-model="cost.cost_name"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Markup Method</label>
          <div class="p-inputgroup">
            <InputText
              v-if="cost.markup_method"
              placeHolder="Markup Method"
              v-model="cost.markup_method.name"
              disabled
            />
            <InputText
              v-else
              placeHolder="Markup Method"
              v-model="cost.markup_method"
              disabled
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.cost.markup_method.required"
          >
            Markup method is required
          </div>
        </div>

        <div class="field col-12">
          <label class="font-light" for="Description">Description</label>
          <Editor
            ref="editor"
            id="Description"
            :autoResize="true"
            editorStyle="height:150px"
            v-model="cost.description"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-align"></button>
                <button class="ql-link"></button>
              </span> </template
          ></Editor>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.cost.description.required"
          >
            Description is required
          </div>
        </div>
      </div>
      <div v-if="cost.markup_method">
        <div class="flex">
          <div class="text-xl col pl-0 pb-3">Cost Detail</div>
        </div>

        <div
          class="grid col-12 cursor-pointer pl-0"
          style="margin: 0 !important"
        >
          <div class="field col">
            <label>Units</label>
            <div class="p-inputgroup">
              <InputNumber
                style="width: 50%"
                :min="0.01"
                class="text-center mr-2"
                v-model="cost.units"
                mode="decimal"
                :minFractionDigits="2"
                @input="calculateResellAmount()"
                placeholder="Quantity"
              />
            </div>
          </div>
          <div class="field col">
            <label>Unit cost</label>
            <div class="p-inputgroup">
              <InputNumber
                style="width: 50%"
                :min="0.01"
                class="text-center mr-2"
                mode="decimal"
                :maxFractionDigits="6"
                :minFractionDigits="2"
                v-model="cost.unit_cost"
                @input="calculateResellAmount()"
                placeholder="Cost"
              />
            </div>
          </div>
          <div class="field col">
            <label>Markup</label>
            <div class="p-inputgroup">
              <InputNumber
                :suffix="setSuffix"
                style="width: 50%"
                class="text-center mr-2"
                mode="decimal"
                :minFractionDigits="2"
                v-model="cost.markup"
                @input="calculateResellAmount()"
                placeholder="Markup"
              />
            </div>
          </div>
          <div class="field col">
            <label>Unit Price</label>
            <div class="p-inputgroup col text-center align-items-center">
              {{ formatCurrency(cost.resell_amount, currencySymbol, 8) }}
            </div>
          </div>
          <div class="field col">
            <label>Total Price</label>
            <div class="p-inputgroup col text-center align-items-center">
              {{
                formatCurrency(
                  cost.resell_amount * cost.units,
                  currencySymbol,
                  8
                )
              }}
            </div>
          </div>
          <Divider class="m-2 mb-3"></Divider>
        </div>
      </div>
      <div class="flex justify-content-between pt-5" v-if="cost.markup_method">
        <div>
          <Button type="submit"
            ><template v-if="!costObj">Add</template
            ><template v-else>Update</template> Cost</Button
          >
        </div>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>
  </div>
</template>
    <script>
import {
  fetchAccountCostTypes,
  fetchCurrencyConversion,
  fetchAccountSuppliers,
  state,
} from "../../../services/data_service.js";
import { temp_state } from "../../../services/temp_state_service.js";
import { required } from "vuelidate/lib/validators";

export default {
  name: "JobCostSlider",
  components: {},
  data() {
    return {
      isLoading: false,
      submitted: false,
      isApprove: false,
      displayOptionIcon: null,
      costFormMode: false,

      cost: {
        id: null,
        item: null,
        units: 1,
        cost_type: null,
        quantity: 0,
        type_name: null,
        cost_name: null,
        currency_name: null,
        markup_method: null,
        resell_amount: 0,
        supplier: null,
        exists: true,
        description: null,
        type: "cost_type",
      },
      contextMenuItems: [
        {
          label: "Edit Cost",
        },
        {
          label: "Delete Cost",
          command: () => {
            this.deletePrompt(
              this.costObj.type_name,
              "/v1/costs/" + this.cost_uid
            );
            this.clearSliderContent();
          },
        },
      ],
    };
  },
  validations: {
    cost: {
      item: {
        required,
      },
      markup_method: {
        required,
      },
      description: {
        required,
      },
      supplier: {
        required,
      },
    },
  },
  async mounted() {
    await this.loadData();
    if (this.costObj) {
      this.cost.type_name = this.costObj.type_name;
      this.cost.cost_name = this.costObj.title;
      this.cost.cost_type = this.costObj.cost_type;
      this.cost.supplier = this.costObj.supplier_uid;
      this.cost.currency_name = this.costObj.job.currency.name;
      this.cost.markup_method = state.taskTypePricing.find(
        (a) => a.uid === this.costObj.markup_method.uid
      );

      this.cost.units = this.costObj.quantity;
      this.cost.description = this.costObj.description;
      this.cost.unit_cost = this.costObj.unit_price;
      this.cost.markup = this.costObj.markup ?? this.costObj.margin;
      this.calculateResellAmount();
    }
    this.isApprove = temp_state.defaultJobApprove;
  },

  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async loadData() {
      this.isLoading = true;
      await Promise.all([fetchAccountCostTypes(1), fetchAccountSuppliers()]);
      this.isLoading = false;
    },

    async handleCostSelect(cost) {
      if (cost) {
        await fetchCurrencyConversion(1, cost.currency.name, this.currencyName);
        this.cost.item.currency = cost.currency;
        this.cost.currency = cost.currency;
        this.cost.description = cost.description;
        this.cost.supplier = this.accountSuppliers.find(
          (supplier) => supplier.uid === cost.supplier?.uid
        )?.uid;

        this.cost.markup_method = this.taskTypePricing.find(
          (a) => a.uid === cost.markup_method
        );

        if (this.cost.markup_method?.uid === "cfp") {
          this.cost.markup = parseFloat(
            (cost.default_markup * this.currencyConversion).toFixed(2)
          );
        } else {
          this.cost.markup = parseFloat(cost.default_markup);
        }

        this.cost.resell_amount = parseFloat(
          (cost.resell_amount * this.currencyConversion).toFixed(6)
        );
        this.cost.unit_cost = parseFloat(
          (cost.default_cost_price * this.currencyConversion).toFixed(6)
        );
      }
    },
    calculateResellAmount() {
      switch (this.cost.markup_method.uid) {
        case "rph":
        case "cfp":
          this.cost.resell_amount =
            parseFloat(this.cost.unit_cost) + parseFloat(this.cost.markup);
          break;
        case "per":
          this.cost.resell_amount =
            (parseFloat(this.cost.unit_cost) * parseFloat(this.cost.markup)) /
              100 +
            parseFloat(this.cost.unit_cost);
          break;
      }
    },

    submitHandler() {
      if (this.cost_uid) {
        this.cost.item = this.cost.cost_type;
      }
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the fields",
          life: 3000,
        });
      } else {
        const form = new FormData();
        form.append("job", this.job.uid);
        form.append("group", this.group.uid);
        if (!this.cost_uid) {
          form.append("cost_type", this.cost.item.uid);
        } else {
          form.append("cost_type", this.cost.cost_type);
        }
        form.append("description", this.cost.description);
        if (this.cost.cost_name) {
          form.append("title", this.cost.cost_name);
        }
        form.append("supplier_uid", this.cost.supplier);
        form.append("unit_price", this.cost.unit_cost);
        form.append("margin", this.cost.markup);
        form.append("quantity", this.cost.units);
        form.append("is_approved", this.isApprove ? 1 : 0);

        form.append("markup_method", this.cost.markup_method.uid);

        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/costs" +
              (!this.cost_uid ? "" : "/" + this.cost_uid),
            form,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.$toast.add({
              severity: "success",
              summary: "Cost Saved",
              life: 3000,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
        this.clearSliderContent();
      }
    },
    toggle(event) {
      this.$refs.subMenu.toggle(event);
    },
    getContextMenu(array) {
      var arr = [];
      array.forEach((element) => {
        arr.push(this.contextMenuItems[element]);
      });
      return arr;
    },
  },
  computed: {
    items() {
      return this.getContextMenu([0, 1]);
    },
    setSuffix() {
      let suffix = null;
      if (this.cost.markup_method?.name === "Percentage") {
        suffix = "%";
      } else {
        suffix = null;
      }
      return suffix;
    },
    currencyConversion() {
      return state.currencyConversion;
    },
    currencySymbol() {
      return this.job?.client?.currency?.description;
    },
    currencyName() {
      return this.job?.client?.currency?.name;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    taskTypePricing() {
      return state.taskTypePricing;
    },
    job() {
      return this.selectedObject.job;
    },
    group() {
      return this.selectedObject.group;
    },
    cost_uid() {
      return this.selectedObject.cost_uid;
    },
    costObj() {
      return state.cost;
    },
    cost_types() {
      if (state.costTypes) {
        state.costTypes.forEach((c) => {
          c.resell_amount = parseFloat(c.resell_amount);
        });
        return state.costTypes.filter((cost) => cost.active == 1);
      }
      return [];
    },
    accountSuppliers() {
      return state.accountSuppliers.filter((supplier) => supplier.active == 1);
    },
  },
};
</script>
    <style lang="scss" scoped>
</style>